<template>
  <SelectOrderTypeContainer>
    <template
      #select-component="{
        computeds: { getOrderType },
        events: { handlerSelectOrderType },
      }"
    >
      <InputSelectOrderType
        :defaultOrderType="getOrderType"
        :handlerSelectOrderType="handlerSelectOrderType"
      />
    </template>
  </SelectOrderTypeContainer>
</template>
<script>
export default {
  name: "InputSelectOrderTypeContainer",
  components: {
    SelectOrderTypeContainer: () => import("../SelectOrderTypeContainer"),
    InputSelectOrderType: () => import("./InputSelectOrderType"),
  },
};
</script>
